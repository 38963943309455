import {  Component, OnInit,  HostListener, Inject } from '@angular/core';
import { trigger,style,transition,animate,keyframes,query,stagger } from '@angular/animations';

import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [ 

    trigger('cidades', [
      transition('* => *', [
        query(':enter', style ({ opacity: 0 }), {optional: true}),

        query(':enter', stagger('300ms', [
          animate('.6s ease-in' , keyframes([
            style ({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style ({opacity: .5, transform: 'translateY(35px)', offset: .3}),
            style ({opacity: 1, transform: 'translateY(0)', offset: 1}),
            
          ]))
        ]), {optional: true}),

        query(':leave', stagger('300ms', [
          animate('.6s ease-in' , keyframes([
            style ({opacity: 1, transform: 'translateY(0) ', offset: 0}),
            style ({opacity: .5, transform: 'translateY(35px) ', offset: .3}),
            style ({opacity: 0, transform: 'translateY(-75%)', offset: 1}),
            
          ]))
        ]), {optional: true})

      ])
    ])
  ] 
})
export class HomeComponent implements OnInit {

  itemCount: number;
  btnText: String = 'Add an item';
  cidadeText: String = 'My first life cidade';
  cidades = [];
  diretor:string;
  cliente1:String;
  cliente2:String;
  cliente3:String;
  cliente4:String;
  cliente5:String;
  cliente6:String;
  cliente7:String;
  cliente8:String;
  cliente9:String;
  cliente10:String;
  cliente11:String;
  cliente12:String;
  cliente13:String;
  myCenter : String;
  latitude = -29.6968859;
  longitude = -51.1301686;
  myNavbar = "w3-bar"
  navIsFixed: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.diretor = 'assets/img/diretor.jpg';
    this.cliente1 = 'assets/img/clientes/sindhes.png';
    this.cliente2 = 'assets/img/clientes/centraldeservicos.png';
    this.cliente3 = 'assets/img/clientes/unimedparana.png';
    this.cliente4 = 'assets/img/clientes/cianorte.png';
    this.cliente5 = 'assets/img/clientes/foz.jpg';
    this.cliente6 = 'assets/img/clientes/benner.png';
    this.cliente7 = 'assets/img/clientes/Topmed.png';
    this.cliente8 = 'assets/img/clientes/meridional.png';
    this.cliente9 = 'assets/img/clientes/planisa.jpg';
    this.cliente10 = 'assets/img/clientes/sea.jpg';
    this.cliente11 = 'assets/img/clientes/UCEFF.jpg';
    this.cliente12 = 'assets/img/clientes/unidas.png';
    this.cliente13 = 'assets/img/clientes/usp.jpg';

   }

  ngOnInit() { }



  @HostListener('window:scroll', [])
  onWindowScroll() {

    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      // navbar.className = "w3-bar" + " w3-card" + " w3-animate-top" + " w3-white";
       this.myNavbar = 'w3-bar' + ' w3-card' + ' w3-animate-top' + ' w3-white'
   } else {
      // navbar.className = navbar.className.replace(" w3-card w3-animate-top w3-white", "");
       this.myNavbar = 'w3-bar'
   }


// botao voltar para o topo
   if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.navIsFixed = true;
      } else if (this.navIsFixed && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) { this.navIsFixed = false; } } 
      
      scrollToTop() { (function smoothscroll() { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 5));
          }
      })();
  }


  // Used to toggle the menu on small screens when clicking on the menu button
  toggleFunction() {
      var x = document.getElementById('navDemo');
      if (x.className.indexOf('w3-show') === -1) {
          x.className += ' w3-show';
      } else {
          x.className = x.className.replace(' w3-show', '');
      }
  }


}
